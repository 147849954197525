







import { Component, Vue, Watch } from 'vue-property-decorator'

import BeforeBuy from './before-buy.vue'
import AfterBuy from './after-buy.vue'
import ClassTracking from '@/modules/course/pages/course-class/class-tracking'

import {
  getColumnDetail
} from '@/modules/column/api'
import { CourseInfo } from '@/modules/course/models'
import { getPintuanDetail } from '@/modules/common/api/index'
import { PintuanDetail } from '@/modules/common/models/index'
import { checkLogin } from '@/utils/user'

  @Component({
    components: {
      BeforeBuy,
      AfterBuy
    }
  })
export default class CourseIndex extends Vue {
    isLoading: boolean = true
    errorObj: any = null
    courseId: string = ''
    contentInfo: CourseInfo = {}
    pintuanDetail: any = {}
    @Watch('$route')
    on$routeChanged() {
      this.contentInfo = {}
      this.pageInit()
    }

    created() {

    }

    mounted() {
      this.pageInit()
    }

    // 页面初始化
    pageInit() {
      this.courseId = this.$route.params.id
      // return ClassTracking.checkUpload().then(() => {
      return this.getInfo()
      // }, () => {
      //   return this.getInfo()
      // })
    }
    // 获取内容详情
    getInfo () {
      let id = this.courseId

      this.isLoading = true
      return getColumnDetail(id).then(res => {
        if (!res.error) {
          this.contentInfo = res.data
          this.contentAfter()
        } else {
          let errorText = res.message || '服务器异常'
          this.errorObj = {
            html: errorText
          }
        }
        this.isLoading = false
      })
    }
    // 获取详情后执行
    contentAfter() {
      let contentInfo = this.contentInfo

      let lockMode = contentInfo.study_mode === 'sequentially'
      this.$set(this.contentInfo, '_lockMode', lockMode)
      if (this.contentInfo.fightgroup) {
        this.getPintuanDetail()
      }
      // 重置标题
      // utils.windowTitle(contentInfo.title)
      // 设置分享
      // this.wxShare()
      // 取积分信息
      // this.getScoreInfo()
      // 取关联商品信息
      // this.getLinkData()
    }
    // 获取拼团活动详情
    getPintuanDetail() {
      if (!checkLogin()) return
      let id = this.contentInfo.fightgroup
      getPintuanDetail(id).then((res): void => {
        this.pintuanDetail = res
      })
    }
}
