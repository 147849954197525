import { Vue, Component, Prop } from 'vue-property-decorator'
import { CourseInfo } from '@/modules/course/models'

import DetailHeader from '@/modules/common/components/detail-header.vue'
import NavTab from '@/modules/common/components/nav-tab.vue'
import ContentLayout from '@/modules/course/components/content-layout.vue'
import ExtLearning from '@/modules/course/pages/course-detail/components/ext-learning.vue'
// import DetailInfo from './components/detail-info.vue'
// import ClassList from './components/class-list/index.vue'
import Students from '@/modules/course/pages/course-detail/components/students.vue'
import MaterialList from '@/modules/course/pages/course-detail/components/material-list.vue'

@Component({
  components: {
    NavTab,
    // DetailInfo,
    // ClassList,
    DetailHeader,
    ExtLearning,
    Students,
    MaterialList,
    ContentLayout
  }
})
export default class CourseDetailMixins extends Vue {
  @Prop({ default: () => { return {} } }) iptData! :any
  tabList: Array<any> = [
    { name: '介绍', value: 'introduce' },
    { name: '目录', value: 'catalogue' },
  ]
  activeTab: string = 'introduce'
  materialCount: number = 0

  @Prop({ default: () => { return {} } }) courseDetail!: CourseInfo
  @Prop({ default: () => { return {} } }) pintuanDetail!: any

  toggleHandler(val: string) {
    this.activeTab = val
  }

  materialCountChange(count: number) {
    this.materialCount = count
  }
}
