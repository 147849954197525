















import { Component, Vue, Prop, Mixins, Watch } from 'vue-property-decorator'
import CellSubscribeSingle from '@/modules/column/pages/components/cell-subscribe-single.vue'

@Component({
  components: {
    CellSubscribeSingle
  }
})

export default class ClassList extends Vue {
  @Prop() contentObj: any
  @Prop() contentInfo: any

  mounted () {
    
  }
  toPage (item:any) {
    if (this.contentInfo.is_subscribe) {
      // this.$router.push({ path: item.router,query: {class_content: this.contentObj} })
      this.$router.push({ name: 'column',params: {type: item.type,id: item.content_id,column_id: this.contentInfo.column_id} })
    } else {
      this.$message('订阅后才能看')
    }
  }
  // 是否试看
  isTryAndSee(item:any) {
    return item.is_test && !this.contentInfo.is_subscribe
  }
}

