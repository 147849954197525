import { render, staticRenderFns } from "./column-list.vue?vue&type=template&id=5c6655ea&scoped=true&"
import script from "./column-list.vue?vue&type=script&lang=ts&"
export * from "./column-list.vue?vue&type=script&lang=ts&"
import style0 from "./column-list.vue?vue&type=style&index=0&id=5c6655ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c6655ea",
  null
  
)

export default component.exports