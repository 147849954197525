







































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import CourseDetailMixins from './mixins'
import UeditorDisplay from '@/modules/common/components/ueditor-display/index.vue'
import columnList from '@/modules/column/pages/components/column-list.vue'
import { getColumnCountents } from '@/modules/column/api/index'
// eslint-disable-next-line standard/object-curly-even-spacing
// import {filterRouter } from '@/modules/common/components/info-form/components/filters/utils'

@Component({
  components: {
    UeditorDisplay,
    columnList
  }
})
export default class CourseBefore extends Mixins(CourseDetailMixins) {
  items : any = []
  mounted() {
    // tab 栏试学标签
    // if (this.courseDetail.support_services.indexOf('try_learn') != -1) {
    //   let newTab = {
    //     ...this.tabList[1],
    //     tag: '试学'
    //   }
    //   this.tabList.splice(1, 1, newTab)
    // }
    this.getColumnCountents()
    
  }
  get showServerList() {
    return this.serverList.length > 0
  }
  get serverList() {
    let list = [].concat(this.courseDetail.support_services)
    let mapData = {
      try_learn: {
        m: '免费试学',
        s: '可通过试学课时免费体验课程内容'
      // },
      // community: {
      //   m: '交流互动',
      //   s: '专属课程的兴趣组，学员互动交流区，老师在线答疑'
      // },
      // daka: {
      //   m: '随课打卡',
      //   s: '专属课程的打卡，在线督学，提升学习效率'
      // },
      // material: {
      //   m: '课件资料',
      //   s: '随课教辅资料，全面配套讲解，随时在线观看'
      }
    }
    return list.map(e => {
      return mapData[e]
    }).filter(e => !!e)
  }
  // item() {
  //   this.items && filterRouter(this.items, {
  //     isbuy: 1
  //   })
  //   let token = this.$route.query.token
  //   if (this.items) {
  //     this.items.forEach(e => {
  //       if (e.router && token) {
  //         e.router = e.router + '?isFromColumn=1&token=' + token
  //       }
  //     })
  //   }
  //   return this.items || []
  // }
  getColumnCountents() {
    let id = this.courseDetail.column_id
    let params:any = { 
      column_id: id
    }
    getColumnCountents(params).then(res => {
      console.log(res,'res.datares.datares.datares.data');
      this.items = res
      
      
      // this.item()
    })
    
  }
  
}
